<template>
  <div class="vx-row">
    <div class="vx-col w-full  mb-base">
      <vx-card title="Add New Address">
        <form-create :is-contact-address="true" :contact-address-id="$route.params.id" @on-success="$router.push({name: 'contacts-show', params:{id: $route.params.id}})"></form-create>
      </vx-card>
    </div>
  </div>
</template>

<script>
import FormCreate from './sections/Form.vue'
export default {
  components:{
    FormCreate
  }
}
</script>

<style>

</style>